import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import NavbarWrapper from 'reusecore/src/elements/Navbar';
import Logo from 'reusecore/src/elements/UI/Logo';
import Box from 'reusecore/src/elements/Box';
import Drawer from 'reusecore/src/elements/Drawer';
import Button from 'reusecore/src/elements/Button';
import Container from '../../../components/UI/Container';
import { DrawerContext } from '../../../contexts/DrawerContext';

import HamburgMenu from '../../../components/HamburgMenu';
import ScrollSpyMenu from '../../../components/ScrollSpyMenu';

import { MENU_ITEMS } from '../../../data/Saas';

import LogoImage from '../../../assets/image/saas/SignorPOS.svg';

const Navbar = ({
  navbarStyle,
  logoStyle,
  row,
  menuWrapper,
  button = true,
  menu = true,
  ...props
}) => {
  const { state, dispatch } = useContext(DrawerContext);

  // Toggle drawer
  const toggleHandler = () => {
    dispatch({
      type: 'TOGGLE',
    });
  };

  return (
    <NavbarWrapper {...navbarStyle}>
      <Container>
        <Box {...row}>
          <Logo
            to="/"
            logoSrc={LogoImage}
            title="SignorPOS"
            logoStyle={logoStyle}
            withAnchor={false}
          />
          <Box {...menuWrapper}>
            {menu && <ScrollSpyMenu
              className="main_menu"
              menuItems={MENU_ITEMS}
              offset={-70}
            />}
            {menu && <Drawer
              width="420px"
              placement="right"
              drawerHandler={<HamburgMenu barColor="#eb4d4b" />}
              open={state.isOpen}
              toggleHandler={toggleHandler}
            >
              <ScrollSpyMenu
                className="mobile_menu"
                menuItems={MENU_ITEMS}
                drawerClose={true}
                offset={-100}
              />
            </Drawer>}
            {button && (
              <Button
                href="/step-2"
                colors="primaryWithBg"
                title="CONFRONTA I POS"
                fontSize={['13px', '14px']}
                pulse
                display={['none', 'none', 'none', 'flex', 'flex']}
              />
            )}
          </Box>
        </Box>
      </Container>
    </NavbarWrapper>
  );
};

Navbar.propTypes = {
  navbarStyle: PropTypes.object,
  logoStyle: PropTypes.object,
  button: PropTypes.bool,
  row: PropTypes.object,
  menuWrapper: PropTypes.object,
};

Navbar.defaultProps = {
  navbarStyle: {
    className: 'hosting_navbar',
    minHeight: '70px',
    display: 'block',
  },
  row: {
    flexBox: true,
    alignItems: 'center',
    justifyContent: [
      'space-between',
      'space-between',
      'space-between',
      'flex-start',
    ],
    width: '100%',
  },
  logoStyle: {
    maxWidth: '130px',
    width: '130px',
    mr: [0, 0, 0, '166px'],
  },
  menuWrapper: {
    flexBox: true,
    alignItems: 'center',
    flex: ['0', '0', '0', '1', '1'],
    justifyContent: 'space-between',
  },
};

export default Navbar;
